import { useCallback, useRef, FC, ComponentProps, useMemo } from 'react';

import { Input, useBreakpoint } from '@hh.ru/magritte-ui';
import { MagnifierOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { EmployerAddressShort, EMPTY_ITEM, PreparedAddress, PreparedItem } from 'Modules/EmployerAddresses/helpers';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import translation from 'src/components/translation';
import { useSelectOnBlur } from 'src/utils/suggest/useSelectOnBlur';

import AddressItem from 'src/components/AddressSuggest/AddressItem';
import createAddressDataProvider from 'src/components/AddressSuggest/hooks/useAddressProvider';

interface InputProps extends Omit<ComponentProps<typeof Input>, 'onChange'> {
    onChange: (
        value: string,
        {
            element,
        }: {
            element: HTMLElement | null;
        }
    ) => void;
}

interface AddressSuggestProps {
    /** Адреса работодателя, как они лежат в стейте */
    addresses: EmployerAddressShort[];
    /** При установке в true, addresses будет игнорироваться, и саджест будет работать с удаленным поиском адресов */
    remoteSearch: boolean;
    /** Выбранный адрес */
    address: PreparedAddress;
    inputValue: string;
    trls: LangTrls;
    /** Колбэк вызывающийся при выборе элемента из саджеста, принимает выбранный адрес */
    onSelect: (address: PreparedAddress, isOnClear?: boolean, retainSuggestVisibility?: boolean) => void;
    placeholder?: string;
    invalid?: boolean;
    /** Колбэк для установки invalid свойства input-а */
    setInvalid?: (invalid: boolean) => void;
    /** Максимальное количество отображаемых в дропдауне результатов */
    limit: number;
    /** Аналитика клика по инпуту */
    onClickAnalytics?: () => void;
    /** id адреса, который не будет отображаться в дропдауне */
    addressIdNotToShow?: number;
    /** employerId, который нужен чтобы получить список адресов под бэкофисом */
    employerId?: string;
    /** Другие параметры, которые будут переданы в input */
    inputProps: InputProps;
    /** Заголовок в bottom sheet */
    navigationTitle: string;
    /** Максимальная высота дропа с вариантами */
    maxHeight?: number;
}

const AddressSuggest: FC<AddressSuggestProps> = ({
    addresses,
    remoteSearch,
    address,
    inputValue,
    trls,
    onSelect,
    placeholder,
    invalid,

    setInvalid = () => {},
    inputProps,
    limit,
    addressIdNotToShow,
    navigationTitle,

    onClickAnalytics = () => {},
    employerId = '',
    maxHeight,
}) => {
    const { isMobile } = useBreakpoint();

    const inputRef = useRef<HTMLElement>(null);
    const handleInputFocus = () => setInvalid(false);
    const handleInputButtonIconClick = useCallback(() => {
        if (address !== EMPTY_ITEM) {
            onSelect(EMPTY_ITEM, true);
        }
        setTimeout(() => inputRef?.current?.focus(), 0);
    }, [address, onSelect]);

    const handleSelect = (_: string, item?: PreparedItem) => {
        item && onSelect(item);
        return true;
    };

    const [select, pickFirstValue, onSaveFirst] = useSelectOnBlur(inputValue, handleSelect, 1);

    const onBlurSaveFirstAddress = isMobile ? undefined : onSaveFirst;

    const dataProvider = useMemo(
        () =>
            createAddressDataProvider({
                addresses,
                employerId,
                remoteSearch,
                trls,
                onBlurSaveFirstAddress,
                addressIdNotToShow,
                limit,
            }),
        [addresses, employerId, remoteSearch, trls, onBlurSaveFirstAddress, addressIdNotToShow, limit]
    );

    const inputEnabled = !inputProps?.disabled;

    return (
        <>
            <SuggestWithDefaultErrorPlaceholder
                inputValue={inputValue}
                onBlur={pickFirstValue}
                onSelectValidator={select}
                dataProvider={dataProvider}
                ref={inputRef}
                input={{
                    component: Input,
                    props: {
                        ...inputProps,
                        onClick: onClickAnalytics,
                        placeholder,
                        invalid,
                        onFocus: handleInputFocus,
                        buttonIcon: inputEnabled ? MagnifierOutlinedSize24 : undefined,
                        onButtonClick: handleInputButtonIconClick,
                        'data-qa': 'address-suggest-input',
                        clearable: true,
                        onChange: (value) => inputProps.onChange(value, { element: inputRef?.current }),
                    },
                }}
                navigationBarProps={{
                    title: navigationTitle,
                }}
                maxHeight={maxHeight}
                renderItem={({ data: item }) => {
                    if (!item) {
                        return null;
                    }
                    return (
                        <AddressItem
                            id={item.id}
                            displayName={item.displayName}
                            description={item.description}
                            metroStations={item.metroStations}
                        />
                    );
                }}
            />
        </>
    );
};

export default translation(AddressSuggest);
