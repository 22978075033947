import {
    createDataProvider,
    createStaticDataFetcher,
    DataProvider,
    DataProviderResult,
    decorateWithQueryTransformer,
    decorateWithResultProducer,
    defaultFetcher,
} from '@hh.ru/magritte-ui';
import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { EmployerAddressShort, prepareAddresses, PreparedItem } from 'Modules/EmployerAddresses/helpers';
import { updateUrl } from 'Modules/url';
import { CancelCallback, fetcherWithAutoSelect } from 'src/utils/suggest/buildDataProvider';

const AUTOSUGGEST_ADDRESSES_URL = `/employer/addresses/search`;

const TrlKeys = {
    metroStation: 'metrostation',
};

interface UseAddressProviderProps {
    addresses: EmployerAddressShort[];
    employerId: string;
    remoteSearch: boolean;
    trls: LangTrls;
    onBlurSaveFirstAddress: ((data: PreparedItem[]) => void) | undefined;
    addressIdNotToShow?: number;
    limit: number;
}

const formatter = (items: PreparedItem[] | null): DataProviderResult<PreparedItem> => {
    if (!items) {
        return [];
    }
    return items.map((item) => ({
        type: 'cells',
        items: [
            {
                data: item,
                value: item.text,
            },
        ],
    }));
};

const getAddresses = (trls: LangTrls, addressIdNotToShow?: number) => (url: string, onCancel: CancelCallback) =>
    defaultFetcher<{ addresses: EmployerAddressShort[] }>(url, onCancel).then((data) => {
        if (data?.addresses) {
            return prepareAddresses(data.addresses, trls[TrlKeys.metroStation], addressIdNotToShow);
        }
        return [];
    });
const createStaticDataProvider = (
    data: PreparedItem[],
    onBlurSaveFirstAddress: ((data: PreparedItem[]) => void) | undefined
) =>
    createDataProvider({
        fetcher: decorateWithResultProducer(
            fetcherWithAutoSelect({
                fetcher: createStaticDataFetcher(data) as (
                    query: string,
                    onCancel: CancelCallback
                ) => Promise<PreparedItem[]>,
                onBlurSaveFirstValue: onBlurSaveFirstAddress,
            }),

            formatter
        ),
        minCharsCount: 0,
    });

/**
 * Cоздает дата провайдер, поддерживая как удаленное, так и статическое получение данных на основе заданных параметров.
 *
 * @param addresses Массив EmployerAddressShort, который будет использоваться как источник данных при статическом поиске
 * @param employerId id работодателя, используемый при конфигурации URL для удаленного поиска.
 * @param remoteSearch Флаг указывающий, следует ли получать данные удаленно или использовать статические данные.
 * @param trls Переводы.
 * @param onBlurSaveFirstAddress Опциональный коллбэк, выбирающий первый адрес из списка при блюре.
 * @param addressIdNotToShow Опциональный id адреса, который нужно исключить из списка.
 * @param limit Опциональное ограничение на количество адресов из выборки, используемый при конфигурации URL для удаленного поиска
 *
 * @returns Дата провайдер, настроенный на получение и форматирование адресных данных либо из удаленного источника, либо статически, в зависимости от параметра remoteSearch.
 */
const useAddressDataProvider = ({
    addresses,
    employerId,
    remoteSearch,
    trls,
    onBlurSaveFirstAddress,
    addressIdNotToShow,
    limit,
}: UseAddressProviderProps): DataProvider<PreparedItem> => {
    if (remoteSearch) {
        const queryString = (q: string) => updateUrl(AUTOSUGGEST_ADDRESSES_URL, { limit, employerId, searchText: q });

        const decoratedFetcher = decorateWithQueryTransformer(
            fetcherWithAutoSelect({
                fetcher: getAddresses(trls, addressIdNotToShow),
                onBlurSaveFirstValue: onBlurSaveFirstAddress,
            }),
            queryString
        );

        return createDataProvider({
            fetcher: decorateWithResultProducer(decoratedFetcher, formatter),
            minCharsCount: 0,
        });
    }

    const data: PreparedItem[] = prepareAddresses(addresses, trls[TrlKeys.metroStation], addressIdNotToShow);

    return createStaticDataProvider(data, onBlurSaveFirstAddress);
};

export default useAddressDataProvider;
