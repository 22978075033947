import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';

import useIsBackoffice from 'src/hooks/useIsBackoffice';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { appendEmployerAddress } from 'src/models/employerAddresses';
import { EmployerAddress } from 'src/models/employerAddresses.types';
import fetcher from 'src/utils/fetcher';

declare global {
    interface FetcherGetApi {
        // строковый шаблон вида `/employer/addresses/${number}` падает поэтому так
        employerAddressUrlById: {
            queryParams: {
                employerId?: number;
            };
            response: { addresses: EmployerAddress[] };
        };
    }
}

interface UseAddressLoadProps {
    employerId: number;
    addressId?: number | null;
}

type UseAddressLoadHook = (props: UseAddressLoadProps) => {
    loading: boolean;
};

const setEmployerAddressesLoading = makeSetStoreField('isEmployerAddressesLoading');

const useAddressLoading: UseAddressLoadHook = ({ addressId, employerId }) => {
    const dispatch = useDispatch();
    const isBackoffice = useIsBackoffice();
    const loading = useSelectorNonNullable((state) => state.isEmployerAddressesLoading);

    const params = useMemo(() => (isBackoffice ? { employerId } : {}), [employerId, isBackoffice]);

    useEffect(() => {
        const abortController = new AbortController();

        if (!addressId) {
            return undefined;
        }

        dispatch(setEmployerAddressesLoading(true));
        void fetcher
            .get(`/employer/addresses/${addressId}` as 'employerAddressUrlById', {
                params,
                signal: abortController.signal,
            })
            .then((data) => {
                const address = data?.addresses?.[0];
                const actions = [];
                if (address) {
                    actions.push(appendEmployerAddress(address));
                }
                dispatch([...actions, setEmployerAddressesLoading(false)]);
            })
            .catch((e) => {
                const isCancel = fetcher.isCancel(e);
                if (!isCancel) {
                    dispatch(setEmployerAddressesLoading(false));
                }
                if (!fetcher.isAxiosError(e) && !isCancel) {
                    console.error(e);
                    throw e;
                }
            });

        return () => abortController.abort();
    }, [addressId, params, dispatch]);

    return {
        loading,
    };
};

export default useAddressLoading;
